<template>
  <v-card>
    <v-card-title>
      <GenotyperEntryDialog
        :visible="dialog"
        :item="editedItem"
        @save="save"
        @close="close"
      />
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >¿Seguro que quieres eliminar la pregunta?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancelar</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >Eliminar</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-spacer></v-spacer>
      <v-col cols="3">
        <v-btn @click="newItem" color="blue-grey" class="ma-2 white--text">
          Nueva Entrada Genotyper
          <v-icon right dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="searchableFields"
          v-model="activeFilter"
          item-text="text"
          item-value="value"
          label="Campos"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="filterSearch"
          append-icon="mdi-magnify"
          label="Filtrar"
        ></v-text-field>
      </v-col>
    </v-card-title>
    <v-row>
      <v-col cols="12">
        <v-combobox
          v-model="selectedFields"
          :items="allFields"
          label="Columnas"
          multiple
          chips
        ></v-combobox>
      </v-col>
    </v-row>
    <v-data-table
      :headers="selectedHeaders"
      :items="entries"
      :options.sync="options"
      multi-sort
      :server-items-length="totalEntries"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:item.label="{ item }">
        <v-icon small :color="item.label">
          mdi-circle
        </v-icon>
      </template>
      <template v-slot:item.is_in_pgx="{ item }">
        <v-icon small :color="(item.is_in_pgx && 'green') || 'red'">
          {{ (item.is_in_pgx && "mdi-check") || "mdi-close-thick" }}
        </v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Strapi from "@/services/Strapi.js";
import GenotyperEntryDialog from "@/components/mylogy/dialogs/GenotyperEntryDialog.vue";
export default {
  components: { GenotyperEntryDialog },
  data() {
    return {
      totalEntries: 0,
      entries: [],
      filterSearch: null,
      loading: true,
      activeFilter: "data_matrix_key",
      options: {},
      editedIndex: -1,
      dialog: false,
      dialogDelete: false,
      editedItem: {
        id: "",
        data_matrix_key: "",
        polymorphism: "",
        vic_fam: "",
        cau_maf: "",
        is_in_pgx: true,
        result: "",
        phenotype: "",
        frequency: "",
        genotype: "",
        automatic_genotype: "",
        label: ""
      },
      selectedFields: [
        "Data Matrix Key",
        "Resultado",
        "Tiene PGX",
        "Genotipo",
        "Color",
        "Acciones"
      ],
      headers: [
        {
          text: "Data Matrix Key",
          align: "start",
          sortable: true,
          searchable: true,
          value: "data_matrix_key"
        },
        {
          text: "Tiene PGX",
          sortable: true,
          searchable: true,
          value: "is_in_pgx"
        },
        {
          text: "Genotipo",
          sortable: true,
          searchable: true,
          value: "genotype"
        },
        {
          text: "Resultado",
          sortable: true,
          searchable: true,
          value: "result"
        },
        {
          text: "Fenotipo",
          sortable: true,
          searchable: true,
          value: "phenotype"
        },
        {
          text: "Polimorfismo",
          sortable: true,
          searchable: true,
          value: "polymorphism"
        },
        {
          text: "VIC/FAM",
          sortable: true,
          searchable: true,
          value: "vic_fam"
        },
        {
          text: "CAU MAF",
          sortable: true,
          searchable: true,
          value: "cau_maf"
        },
        {
          text: "Frecuencia",
          sortable: true,
          searchable: false,
          value: "frequency"
        },
        {
          text: "Color",
          sortable: true,
          searchable: false,
          value: "label"
        },
        {
          text: "Genotipo Automático",
          sortable: true,
          searchable: false,
          value: "automatic_genotype"
        },
        { text: "Acciones", value: "actions", sortable: false }
      ]
    };
  },
  computed: {
    searchableFields() {
      return this.selectedHeaders.filter(h => h.searchable);
    },
    allFields() {
      return this.headers.map(h => h.text);
    },
    selectedHeaders() {
      return this.headers.filter(h => {
        return this.selectedFields.includes(h.text);
      });
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    },
    filterSearch: {
      handler() {
        this.getDataFromApi();
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  methods: {
    newItem() {
      this.editedItem = Object.assign(
        {},
        {
          id: "",
          data_matrix_key: "",
          polymorphism: "",
          vic_fam: "",
          cau_maf: "",
          result: "",
          phenotype: "",
          is_in_pgx: true,
          frequency: "",
          genotype: "",
          automatic_genotype: "",
          label: ""
        }
      );
      this.dialog = true;
    },
    editItem(item) {
      this.editedIndex = this.entries.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.entries.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      let self = this;
      Strapi.delete(`/genotyper-entries/${this.editedItem.id}`)
        .then(() => {
          self.$alertify.success("Muestra eliminada");
        })
        .catch(() => {
          self.$alertify.error("Ha ocurrido un error");
        })
        .then(() => {
          self.closeDelete();
          self.getDataFromApi();
        });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save(data) {
      let self = this;
      if (this.editedItem.id != "") {
        Strapi.put(`/genotyper-entries/${this.editedItem.id}`, data)
          .then(() => {
            self.$alertify.success("Entrada actualizada");
          })
          .catch(() => {
            self.$alertify.error("Ha ocurrido un error");
          })
          .then(() => {
            self.close();
            self.getDataFromApi();
          });
      } else {
        delete data.id;
        Strapi.post(`/genotyper-entries`, data)
          .then(() => {
            self.$alertify.success("Entrada creada");
          })
          .catch(() => {
            self.$alertify.error("Ha ocurrido un error");
          })
          .then(() => {
            self.close();
            self.getDataFromApi();
          });
      }
    },
    getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const _sort = sortBy
        .map(function(e, i) {
          return `${e}:${(sortDesc[i] && "DESC") || "ASC"}`;
        })
        .join(",");
      const qs = require("qs");

      let params = {
        _start: (page - 1) * itemsPerPage,
        _limit: itemsPerPage
      };

      if (sortBy.length > 0) {
        params._sort = _sort;
      }

      if (this.filterSearch) {
        params._where = {
          _or: [
            { FIELD: this.filterSearch },
            { FIELD_contains: this.filterSearch }
          ]
        };
      }

      const query = qs
        .stringify(params, {
          encodeValuesOnly: true
        })
        .replace(/FIELD/g, this.activeFilter);

      Strapi.get(`/genotyper-entries?${query}`).then(res => {
        this.entries = res.data;
        Strapi.get(`/genotyper-entries/count?${query}`).then(resCount => {
          this.totalEntries = resCount.data;
          this.loading = false;
        });
      });
    }
  }
};
</script>
<style lang="scss"></style>
