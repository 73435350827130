<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <v-card>
      <v-card-title>
        <span class="text-h5"
          >Editar {{ editedItem.data_matrix_key }}
          {{ editedItem.genotype }}</span
        >
      </v-card-title>

      <v-card flat>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <v-text-field
                  v-model="editedItem.data_matrix_key"
                  label="Data Matrix Key"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="2">
                <v-text-field
                  v-model="editedItem.result"
                  label="Resultado"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="2">
                <v-text-field
                  v-model="editedItem.genotype"
                  label="Genotipo"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="2">
                <v-text-field
                  v-model="editedItem.automatic_genotype"
                  label="Genotipo Aut."
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="2">
                <v-select
                  v-model="editedItem.label"
                  :items="['gray', 'green', 'yellow', 'orange', 'red']"
                  label="Color"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-text-field
                  v-model="editedItem.polymorphism"
                  label="Polimorfismo"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-text-field
                  v-model="editedItem.vic_fam"
                  label="VIC/FAM"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-text-field
                  v-model="editedItem.cau_maf"
                  label="CAU MAF"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-text-field
                  v-model="editedItem.frequency"
                  label="Frecuencia"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-switch
                  v-model="editedItem.is_in_pgx"
                  label="Está en PGX"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="editedItem.phenotype"
                  label="Fenotipo"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">
          Cancelar
        </v-btn>
        <v-btn color="blue darken-1" text @click="save">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["visible", "item"],
  data: () => {
    return {
      tab: "es",
      editedItem: {},
      dialog: false
    };
  },
  mounted() {
    this.editedItem = this.item;
    this.dialog = this.visible;
  },
  watch: {
    item: {
      handler(val) {
        this.editedItem = val;
      },
      deep: true
    },
    visible: {
      handler(val) {
        this.dialog = val;
      }
    }
  },
  methods: {
    save() {
      this.$emit("save", this.editedItem);
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>
